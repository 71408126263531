function hideNotif() {
    var notif = document.querySelector('#copy-ok-notif');
    notif.style.display = "none";
}

function showNotif() {
    var notif = document.querySelector('#copy-ok-notif');
    console.log(notif)
    console.log(getComputedStyle(notif).display);
    if (getComputedStyle(notif).display === "none") {
        notif.style.display = "block";

        setTimeout(function() {
            hideNotif()
        }, 3000);
    }
}

async function copyCCAM(code) {
    // Clean code
    var re = /^(.+?) <i/g;
    var cleant = re.exec(code)[1];
    // Copy the text
    try {
        await navigator.clipboard.writeText(cleant);
    } catch (err) {
    }
}

